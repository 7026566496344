import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Login.css';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

function Forgotpassword() {
  const [email, setEmail] = useState('');
  const [loading,setLoading]=useState(false)

  const navigate = useNavigate();

  const handleLogin = () => {
    // Handle login logic here
    setLoading(true)


    fetch("https://api.bombastic.ps/api/forgotpassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json", 
      },
      body: JSON.stringify({
        email: email
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response as JSON
      })
      .then((data) => {
        // Handle the response data here
        setLoading(false)
        if(data.status=="success"){
        toast.success(data.msg)

        navigate("/otpcheck?email="+email)
        }
        else{

          toast.error(data.msg)

        }
      
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      }); 
    
    
    };

  return (
    <div className="login-container">
      <ToastContainer/>
      <div className="login-box">
        <img src="logo.png" alt="الشعار" className="login-logo" />
        <h2 className="login-header">نسيت كلمة المرور</h2>
        <form>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              placeholder="البريد الإلكتروني"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
         
          <button
            type="button"
            className="btn btn-primary btn-block"
            disabled={loading}
            onClick={handleLogin}
          >
          ارسال الرمز
          </button>
          
          <div className="text-center mt-3">
           <Link to="/login">العودة لصفحة تسجيل الدخول</Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Forgotpassword;
