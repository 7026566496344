import React, { useEffect, useState } from 'react';
import './Tree.css';
import { Link, useParams } from "react-router-dom";


function Tree() {
  const { id } = useParams();

  const [profileData,setProfileData]=useState({"profilepic":"https://api.bombastic.ps/uploads/1698759849084ÙÙØ·Ø© Ø§ÙØ´Ø§Ø´Ø© 2023-10-31 154356.png","backgroundType":"solid","backgroundColor":"#ffffff","title":"","subtitle":"","buttons":[],"designOptions":{"titleFontFamily":"El Messiri","titleFontColor":"#ffffff","subtitleFontFamily":"El Messiri","subtitleFontColor":"white","buttonBackgroundColor":"#ffffff","buttonFontFamily":"Cairo","buttonFontColor":"#ffffff","profilePicStyle":"circle"}})



  useEffect(()=>{

    document.title=id
    fetch('https://api.bombastic.ps/api/getLinksData/'+id, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json', // Set the content type to JSON
        // You can also include other headers as needed
      },
     
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); // Parse the response as JSON
    })
    .then(data => {
      // Handle the response data here
      console.log(data)
      setProfileData(JSON.parse(data.data))
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    });
    
  },[])

  // Determine the background type and set the background style accordingly
  let backgroundStyle;
  if (profileData.backgroundType === "solid") {
    backgroundStyle = { backgroundColor: profileData.backgroundColor };
  } else if (profileData.backgroundType === "gradient" && Array.isArray(profileData.backgroundColor) && profileData.backgroundColor.length === 2) {
    backgroundStyle = {
      background: `linear-gradient(to bottom, ${profileData.backgroundColor[0]}, ${profileData.backgroundColor[1]})`,
    };
  } else {
    // Fallback in case of invalid or missing data
    backgroundStyle = { backgroundColor: 'white' };
  }
  return (
    <div className={`Tree`} style={backgroundStyle}>

      <div className="profile-container">
      <div >
  <img src={profileData.profilepic} alt="Profile" className={`profile-pic ${profileData.designOptions.profilePicStyle}`}/>
</div>
        <h1
  className="profile-title"
  style={{
    fontFamily: profileData.designOptions.titleFontFamily,
    color: profileData.designOptions.titleFontColor,
  }}
>
  {profileData.title}
</h1>
<p
  className="profile-subtitle"
  style={{
    fontFamily: profileData.designOptions.subtitleFontFamily,
    color: profileData.designOptions.subtitleFontColor,
  }}
>
  {profileData.subtitle}
</p>
<div className="profile-buttons">
  {profileData.buttons.map((button, index) => (
    <a
      key={index}
      href={button.link}
      className="profile-button"
      style={{
        fontFamily: profileData.designOptions.buttonFontFamily,

        backgroundColor: profileData.designOptions.buttonBackgroundColor,
        color: profileData.designOptions.buttonFontColor,
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {button.text}
    </a>
  ))}
</div>
            <Link
     
     to={"/signup"}
      className="profile-button"
      style={{
        fontFamily: profileData.designOptions.buttonFontFamily,
        position:"fixed",
        backgroundColor: profileData.designOptions.buttonBackgroundColor,
        color: profileData.designOptions.buttonFontColor,
    position: "absolute",
    top: 0,
    right: 0,
    width: "auto",
    fontSize:"medium",
    borderRadius:10
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      أنشئ صفحتك
    </Link>
       
      </div>
     
     {/*
     <footer className="footer">
        <a
          className='link'
          href='https://bombastic.ps'
        >
          تم التصميم بوساطة شركة بومباستك للدعاية والإعلان
        </a>
      </footer>
     
     */} 
    </div>
  );
}

export default Tree;
