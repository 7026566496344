import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.js';
import 'bootstrap/dist/css/bootstrap.min.css'; // Add this line

ReactDOM.render(
  <React.StrictMode>
<div id="root" dir="rtl">
    <App />
  </div>  </React.StrictMode>,
  document.getElementById('root')
);
