import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import { TwitterPicker } from "react-color";
import AspectRatio from "@mui/joy/AspectRatio/index.js";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import "./formData.css"
  import { useNavigate } from "react-router-dom";
  import copy from 'clipboard-copy';

const initialFormData = {
  username:"",
  profilepic: null,
  backgroundType: "solid",
  backgroundColor: "#ffffff",
  title: "",
  subtitle: "",
  buttons: [{ text: "", link: "" }],
  designOptions: {
    titleFontFamily: "Cairo",
    titleFontColor: "white",
    subtitleFontFamily: "Cairo",
    subtitleFontColor: "white",
    buttonBackgroundColor: "black",
    buttonFontFamily: "Cairo",
    buttonFontColor: "white",
    profilePicStyle: "circle",
  },
};
const arabicFonts = [
  {
    arFontName: "الخط العربي 1",
    enFontName: "Scheherazade",
  },
  {
    arFontName: "الخط العربي 2",
    enFontName: "Reem+Kufi",
  },
  {
    arFontName: "الخط العربي 3",
    enFontName: "Lalezar",
  },
  {
    arFontName: "الخط العربي 4",
    enFontName: "Lateef",
  },
  {
    arFontName: "الخط العربي 5",
    enFontName: "Droid Arabic Naskh",
  },
  {
    arFontName: "الخط العربي 6",
    enFontName: "Mada",
  },
  {
    arFontName: "الخط العربي 7",
    enFontName: "El Messiri",
  },
  {
    arFontName: "الخط العربي 8",
    enFontName: "Amiri",
  },
  {
    arFontName: "الخط العربي 9",
    enFontName: "Changa",
  },
  {
    arFontName: "الخط العربي 10",
    enFontName: "Tajawal",
  },
  {
    arFontName: "الخط العربي 11",
    enFontName: "Rubik",
  },
  {
    arFontName: "الخط العربي 12",
    enFontName: "Cairo",
  },

  // Add more font objects as needed
];
const FormData1 = () => {
  const [formData, setFormData] = useState(initialFormData);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedColorKey, setSelectedColorKey] = useState("");
  const [buttonCount, setButtonCount] = useState(
    initialFormData.buttons.length
  );
  const [iframeKey, setIframeKey] = useState(0);
  const [activateRefresh, setActicvateRefresh] = useState(false);
  const [username,setusername]=useState("")
  const [usernameError,setusernameError]=useState(false)
  const [loading,setLoading]=useState(false)
  const [visits,setvisits]=useState(0)
  const navigate = useNavigate();
  const colorPickerRefs = useRef({});

  useEffect(() => {
    const handleClickOutside = (event) => {
      for (const key in colorPickerRefs.current) {
        if (colorPickerRefs.current[key] && !colorPickerRefs.current[key].contains(event.target)) {
          // Clicked outside the color picker, so close it
          setShowColorPicker(false)
        }
      }
    };

    // Add event listener to detect clicks outside the color pickers
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Remove event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [colorPickerRefs]);

  const handleCopyToClipboard = () => {
    const routeLink = "https://tab3ni.bombastic.ps" +"/profile/"+ username;

    copy(routeLink)
      .then(() => {
        console.log('Link copied to clipboard');
        toast.success("تم نسخ رابط الصفحة")
      })
      .catch((error) => {
        console.error('Failed to copy link: ', error);
      });
  };

  const getUserData=()=>{
    fetch("https://api.bombastic.ps/api/getUserData", {
      method: "get",
      headers: {
        "Content-Type": "application/json", // Set the content type to JSON
        Authorization: "bearer " + sessionStorage.getItem("token"),

        // You can also include other headers as needed
      },
    })
      .then((response) => {
        if (!response.ok) {
          navigate("/login")
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response as JSON
      })
      .then((data) => {
        // Handle the response data here
        console.log(data);
        setFormData(JSON.parse(data.data.jsonData));
        setusername(JSON.parse(data.data.jsonData).username)
        setvisits(data.data.visits)
        console.log(data.data.visits)
        setActicvateRefresh(true);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }
  useEffect(() => {
    getUserData()
  }, []);


  const save=()=>{
    setLoading(true)
    if (activateRefresh) {
      fetch("https://api.bombastic.ps/api/saveLinksData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set the content type to JSON
          Authorization: "bearer " + sessionStorage.getItem("token"),
          // You can also include other headers as needed
        },
        body: JSON.stringify({
          jsonData: JSON.stringify(formData),
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json(); // Parse the response as JSON
        })
        .then((data) => {
          // Handle the response data here
          setLoading(false)
          if(data.status=="success"){
              setusername(formData.username)
          setIframeKey(iframeKey+1)
          setusernameError(false)
          toast.success("تم حفظ البيانات بنجاح")
          getUserData()

          }
          else{

            setusernameError(true)
            toast.error("اسم المستخدم غير متاح")
            getUserData()

          }
        
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
    }
  }

  const deleteButton = (index) => {
    setButtonCount(buttonCount - 1);

    const updatedButtons = [...formData.buttons];
    updatedButtons.splice(index, 1);

    setFormData((prevData) => ({
      ...prevData,
      buttons: updatedButtons,
    }));
  };

  const handleButtonInputChange = (event) => {
    const { name, value } = event.target;
    const buttonIndex = parseInt(name.match(/\d+/)[0], 10);

    // Create a copy of the form data
    const updatedFormData = { ...formData };
    updatedFormData.buttons[buttonIndex][
      name.includes("text") ? "text" : "link"
    ] = value;

    // Set the updated form data
    setFormData(updatedFormData);
  };

  const addNewButton = () => {
    setButtonCount(buttonCount + 1);
    setFormData((prevData) => ({
      ...prevData,
      buttons: [...prevData.buttons, { text: "", link: "" }],
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    //setShowColorPicker(false);


  
    {
  // Split the name into an array of keys
  const keys = name.split(".");

  if (keys.length === 1) {
    // If it's not a nested key, handle it as usual

    if (name === "backgroundType" && value === "solid") {
      setFormData({
        ...formData,
        [name]: value,
        backgroundColor: "#000000",
      });

      //setShowColorPicker(false);
    } else if (name === "backgroundType" && value === "gradient") {
      setFormData({
        ...formData,
        [name]: value,
        backgroundColor: ["#000000", "#ffffff"],
      });

      //setShowColorPicker(false);
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  } else {
    // If it's a nested key, update it correctly
    setFormData((prevData) => {
      const updatedData = { ...prevData };
      let currentData = updatedData;

      // Traverse the object to find the correct property to update
      for (let i = 0; i < keys.length - 1; i++) {
        currentData = currentData[keys[i]];
      }

      currentData[keys[keys.length - 1]] = value;

      return updatedData;
    });
  }
    }
  
  };

  const toggleColorPicker = (key) => {
    setSelectedColorKey(key);
    setShowColorPicker(!showColorPicker);
  };

  const handleBackgroundColorChange = (color, index) => {
    const updatedFormData = { ...formData };
    updatedFormData.backgroundColor[index] = color.hex;
    setFormData(updatedFormData);

    //setShowColorPicker(false);
  };

  const handleColorChange = (color) => {
    const updatedFormData = { ...formData };

    // Split the selectedColorKey to handle nested keys
    const keys = selectedColorKey.split(".");
    let currentObj = updatedFormData;

    // Traverse the object to find the correct property to update
    for (let i = 0; i < keys.length - 1; i++) {
      currentObj = currentObj[keys[i]];
    }

    // Update the last property with the new color
    currentObj[keys[keys.length - 1]] = color.hex;

    setFormData(updatedFormData);

    //setShowColorPicker(false);
  };
  const handleFontFamilyChange = (fontFamily, key) => {
    setFormData((prevData) => {
      // Determine the correct design option to update based on the key
      const updatedDesignOptions = { ...prevData.designOptions };
      updatedDesignOptions[key] = fontFamily;

      return {
        ...prevData,
        designOptions: updatedDesignOptions,
      };
    });
  };

  const uploadProfilePic = async (profilePicFile) => {
    setFormData((prevData) => ({
      ...prevData,
      profilepic: null,
    }));

    if (profilePicFile) {
      const formData = new FormData();
      formData.append("file", profilePicFile);

      try {
        const response = await fetch(
          "https://api.bombastic.ps/api/upload_tab3ni",
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          const data = await response.json();
          const profilePicUrl = data.fileUrl;

          // Update the form data with the new profile picture URL
          setFormData((prevData) => ({
            ...prevData,
            profilepic: profilePicUrl,
          }));
        } else {
          console.error("Failed to upload the profile picture.");
        }
      } catch (error) {
        console.error("Error uploading the profile picture:", error);
      }
    }
  };

  return (
    <div style={{}}>
              <ToastContainer 

              style={{
                zIndex:999999999,
                fontFamily:"Nahdi"
              }}
              />



            
      <Row
        style={{
          marginRight: "0px",
        }}
      >
        <Col md="4">
          <div
            style={{
              padding: "50px",
            }}
          >
            <AspectRatio
              variant="outlined"
              ratio="9/16"
              sx={{
                maxWidth: 400,
                bgcolor: "background.level2",
                borderRadius: "md",
              }}
            >
              <iframe
                key={iframeKey} // Change the key to refresh the iframe
                src={"/profile/"+username}
                style={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
              />
               
            </AspectRatio>
            <div
            style={{
              maxWidth:400
            }}
            >
 <div
              style={{
              
                width:70,
                height:70,
                marginRight:"auto",
                marginLeft:"auto",
                marginTop:5
              }}
              >

<div

style={{
  width:"100%"
}}>
 <img
              src="./eye.png"
              style={{
                width:40,
                marginRight:"auto",
                marginLeft:"auto",
                display:"block"
              }}
              />
              <p
              style={
                {
                  fontFamily: "Cairo",

                textAlign:"center",
                fontWeight:"bold"
                }
              }
              >{visits} زيارة</p>
</div>

             
              </div>
            </div>
           
          </div>
        </Col>

        <Col md="8">
          <div
            className="container rtl-form"
            style={{
              fontFamily: "Cairo",
              padding: "50px",
            }}
          >
            <Form>
              <Form.Group controlId="username">
                <Form.Label
                  style={{
                    marginTop: "20px",

                    fontWeight: "bold",
                  }}
                >
                  اسم المستخدم
                </Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  value={formData.username}
                  style={{
                    borderColor:usernameError?"red":""
                  }}
                  onChange={handleInputChange}
                />

                  {
                    usernameError &&(

                      
                <p style={{
                  fontSize:12,
                  color:"red",

                }}>اسم المستخدم هذا غير متاح</p>
                    )
                  }
              </Form.Group>

              {/* Profile Pic */}
              <Form.Group controlId="profilepic">
                <Form.Label style={{ marginTop: "20px", fontWeight: "bold" }}>
                  الصورة شخصية
                </Form.Label>
                <Form.Control
                  type="file"
                  id="profilepic"
                  custom
                  onChange={(e) => uploadProfilePic(e.target.files[0])}
                />
              </Form.Group>

              {/* Background Type */}
              <Form.Group controlId="backgroundType">
                <Form.Label
                  style={{
                    marginTop: "20px",

                    fontWeight: "bold",
                  }}
                >
                  نوع الخلفية
                </Form.Label>
                <Form.Control
                  as="select"
                  name="backgroundType"
                  value={formData.backgroundType}
                  onChange={handleInputChange}
                >
                  <option value="gradient">تدرج</option>
                  <option value="solid">صلبة</option>
                </Form.Control>
              </Form.Group>

              {/* Background Color */}
              <Form.Group controlId="backgroundColor">
                <Form.Label
                  style={{
                    marginTop: "20px",

                    fontWeight: "bold",
                  }}
                >
                  لون الخلفية
                </Form.Label>
                {formData.backgroundType === "gradient" ? (
                  <Row>
                    {formData.backgroundColor.map((color, index) => (
                      <Col key={index}>
                        {index == 0 ? (
                          <p
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            اللون الاول
                          </p>
                        ) : (
                          <p
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            اللون الثاني
                          </p>
                        )}
                        <div
                          className="color-rectangle"
                          style={{
                            background: color,
                            width: "50px",
                            height: "25px",
                            borderWidth: "1px",
                            borderColor: "black",
                            borderStyle: "solid",
                          }}
                          onClick={() =>
                            toggleColorPicker(`backgroundColor[${index}]`)
                          }
                        ></div>
                        {showColorPicker &&
                          selectedColorKey === `backgroundColor[${index}]` && (
                            <div key={"colorpicker0"} ref={(ref) => (colorPickerRefs.current[0] = ref)}>
                            <TwitterPicker
                              color={color}
                              onChange={(color) =>
                                handleBackgroundColorChange(color, index)
                              }
                            />
                          </div>
                           
                          )}
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <div>
                    <div
                      className="color-rectangle"
                      style={{
                        background: formData.backgroundColor,
                        width: "50px",
                        height: "25px",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid",
                      }}
                      onClick={() => toggleColorPicker("backgroundColor")}
                    ></div>
                    {showColorPicker &&
                      selectedColorKey === "backgroundColor" && (
                        <div key={"colorpicker1"} ref={(ref) => (colorPickerRefs.current[1] = ref)}>
                          <TwitterPicker
                          color={formData.backgroundColor}
                          onChange={(color) =>
                            handleInputChange({
                              target: {
                                name: "backgroundColor",
                                value: color.hex,
                              },
                            })
                          }
                        />
                      </div>
                     
                      )}
                  </div>
                )}
              </Form.Group>

              {/* Title */}
              <Form.Group controlId="title">
                <Form.Label
                  style={{
                    marginTop: "20px",

                    fontWeight: "bold",
                  }}
                >
                  العنوان
                </Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Subtitle */}
              <Form.Group controlId="subtitle">
                <Form.Label
                  style={{
                    marginTop: "20px",
                    fontWeight: "bold",
                  }}
                >
                  العنوان الفرعي
                </Form.Label>
                <Form.Control
                  type="text"
                  name="subtitle"
                  value={formData.subtitle}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Buttons */}
              <Form.Group controlId="buttons">
                <Form.Label
                  style={{
                    marginTop: "30px",
                    fontWeight: "bold",
                  }}
                >
                  الازرار
                </Form.Label>
                {formData.buttons.map((button, index) => (
                  <Row
                    key={index}
                    style={{
                      marginBottom: "20px",
                    }}
                  >
                    <Col xs={9} sm={9} md={11}>
                      <Form.Control
                        type="text"
                        style={{
                          marginBottom: "5px",
                        }}
                        placeholder="النص"
                        name={`buttons[${index}].text`}
                        value={button.text}
                        onChange={handleButtonInputChange}
                      />
                    </Col>
                    <Col xs={9} sm={9} md={11}>
                      <Form.Control
                        type="text"
                        placeholder="الرابط"
                        name={`buttons[${index}].link`}
                        value={button.link}
                        onChange={handleButtonInputChange}
                      />
                    </Col>
                    <Col xs={3} sm={3} md={1}>
                      <Button
                        variant="danger"
                        onClick={() => deleteButton(index)}
                        block
                      >
                        حذف
                      </Button>
                    </Col>
                  </Row>
                ))}
                <Button variant="primary" onClick={addNewButton} block>
                  اضافة زر
                </Button>
              </Form.Group>

              {/* Design Options */}
              <Form.Group controlId="designOptions">
                <Form.Label
                  style={{
                    marginTop: "30px",
                    fontWeight: "bold",
                  }}
                >
                  خيارات التصميم
                </Form.Label>
                {/* Title Font Family */}
                <Row>
                  <Col>
                    <Form.Group controlId="designOptions.titleFontFamily">
                      <Form.Label
                        style={{
                          marginTop: "20px",

                          fontWeight: "bold",
                        }}
                      >
                        خط العنوان
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={formData.designOptions.titleFontFamily}
                        style={{
                          fontFamily: formData.designOptions.titleFontFamily,
                        }}
                        onChange={(e) =>
                          handleFontFamilyChange(
                            e.target.value,
                            "titleFontFamily"
                          )
                        }
                      >
                        {arabicFonts.map((font, index) => (
                          <option
                            key={index}
                            value={font.enFontName}
                            style={{
                              fontFamily: font.enFontName,
                            }}
                          >
                            {font.arFontName}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="titleFontColor">
                      <Form.Label
                        style={{
                          marginTop: "20px",

                          fontWeight: "bold",
                        }}
                      >
                        لون العنوان
                      </Form.Label>
                      <div
                        className="color-rectangle"
                        style={{
                          background: formData.designOptions.titleFontColor,
                          width: "50px",
                          height: "25px",
                          borderWidth: "1px",
                          borderColor: "black",
                          borderStyle: "solid",
                        }}
                        onClick={() =>
                          toggleColorPicker("designOptions.titleFontColor")
                        }
                      ></div>
                      {showColorPicker &&
                        selectedColorKey === "designOptions.titleFontColor" && (
                          <div key={"colorpicker2"} ref={(ref) => (colorPickerRefs.current[2] = ref)}>
                        <TwitterPicker
                            color={formData.designOptions.titleFontColor}
                            onChange={handleColorChange}
                          />
                      </div>
                         
                        )}
                    </Form.Group>
                  </Col>
                </Row>

                {/* Subtitle Font Family */}
                <Row>
                  <Col>
                    <Form.Group controlId="designOptions.subtitleFontFamily">
                      <Form.Label
                        style={{
                          marginTop: "20px",

                          fontWeight: "bold",
                        }}
                      >
                        خط العنوان الفرعي
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={formData.designOptions.subtitleFontFamily}
                        style={{
                          fontFamily: formData.designOptions.subtitleFontFamily,
                        }}
                        onChange={(e) =>
                          handleFontFamilyChange(
                            e.target.value,
                            "subtitleFontFamily"
                          )
                        }
                      >
                        {arabicFonts.map((font, index) => (
                          <option
                            key={index}
                            value={font.enFontName}
                            style={{
                              fontFamily: font.enFontName,
                            }}
                          >
                            {font.arFontName}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="subtitleFontColor">
                      <Form.Label
                        style={{
                          marginTop: "20px",

                          fontWeight: "bold",
                        }}
                      >
                        لون العنوان الفرعي
                      </Form.Label>
                      <div
                        className="color-rectangle"
                        style={{
                          background: formData.designOptions.subtitleFontColor,
                          width: "50px",
                          height: "25px",
                          borderWidth: "1px",
                          borderColor: "black",
                          borderStyle: "solid",
                        }}
                        onClick={() =>
                          toggleColorPicker("designOptions.subtitleFontColor")
                        }
                      ></div>
                      {showColorPicker &&
                        selectedColorKey ===
                          "designOptions.subtitleFontColor" && (
                            <div key={"colorpicker3"} ref={(ref) => (colorPickerRefs.current[3] = ref)}>
                       <TwitterPicker
                            color={formData.designOptions.subtitleFontColor}
                            onChange={handleColorChange}
                          />
                      </div>
                          
                        )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group controlId="buttonBackgroundColor">
                      <Form.Label
                        style={{
                          marginTop: "20px",

                          fontWeight: "bold",
                        }}
                      >
                        لون خلفية الأزرار
                      </Form.Label>
                      <div
                        className="color-rectangle"
                        style={{
                          background:
                            formData.designOptions.buttonBackgroundColor,
                          width: "50px",
                          height: "25px",
                          borderWidth: "1px",
                          borderColor: "black",
                          borderStyle: "solid",
                        }}
                        onClick={() =>
                          toggleColorPicker(
                            "designOptions.buttonBackgroundColor"
                          )
                        }
                      ></div>
                      {showColorPicker &&
                        selectedColorKey ===
                          "designOptions.buttonBackgroundColor" && (
                            <div key={"colorpicker4"} ref={(ref) => (colorPickerRefs.current[4] = ref)}>
                       <TwitterPicker
                            color={formData.designOptions.buttonBackgroundColor}
                            onChange={handleColorChange}
                          />
                      </div>
                          
                        )}
                    </Form.Group>
                  </Col>
                </Row>

                {/* Button Font Family */}

                <Form.Group controlId="designOptions.buttonFontFamily">
                  <Form.Label
                    style={{
                      marginTop: "20px",

                      fontWeight: "bold",
                    }}
                  >
                    خط الأزرار
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={formData.designOptions.buttonFontFamily}
                    style={{
                      fontFamily: formData.designOptions.buttonFontFamily,
                    }}
                    onChange={(e) =>
                      handleFontFamilyChange(e.target.value, "buttonFontFamily")
                    }
                  >
                    {arabicFonts.map((font, index) => (
                      <option
                        key={index}
                        value={font.enFontName}
                        style={{
                          fontFamily: font.enFontName,
                        }}
                      >
                        {font.arFontName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                {/* Button Font Color */}
                <Form.Group controlId="buttonFontColor">
                  <Form.Label
                    style={{
                      marginTop: "20px",

                      fontWeight: "bold",
                    }}
                  >
                    لون خط الأزرار
                  </Form.Label>
                  <div
                    className="color-rectangle"
                    style={{
                      background: formData.designOptions.buttonFontColor,
                      width: "50px",
                      height: "25px",
                      borderWidth: "1px",
                      borderColor: "black",
                      borderStyle: "solid",
                    }}
                    onClick={() =>
                      toggleColorPicker("designOptions.buttonFontColor")
                    }
                  ></div>
                  {showColorPicker &&
                    selectedColorKey === "designOptions.buttonFontColor" && (
                      <div key={"colorpicker5"} ref={(ref) => (colorPickerRefs.current[5] = ref)}>
                        <TwitterPicker
                        color={formData.designOptions.buttonFontColor}
                        onChange={handleColorChange}
                      />
                      </div>
                     
                    )}
                </Form.Group>

                {/* Profile Pic Style */}
                <Form.Group controlId="profilePicStyle">
                  <Form.Label
                    style={{
                      marginTop: "20px",

                      fontWeight: "bold",
                    }}
                  >
                    شكل الصورة الشخصية
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="designOptions.profilePicStyle"
                    value={formData.designOptions.profilePicStyle}
                    onChange={handleInputChange}
                  >
                    <option value="circle">دائرة</option>
                    <option value="rectangle">مستطيل</option>
                  </Form.Control>
                </Form.Group>
              </Form.Group>
              <Button
                variant="primary"
                type="button"
                disabled={loading}
                style={{
                  marginTop: "30px",
                  
                }}
                onClick={() => {
                  save()
                }}
              >
                حفظ
              </Button>
              <Button
                variant="primary"
                type="button"
                style={{
                  marginTop: "30px",
                  marginRight:"20px"
                  
                }}
                onClick={() => {
                  handleCopyToClipboard()
                }}
              >
                نسخ رابط الصفحة
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FormData1;
