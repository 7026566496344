import React from "react";
import DynamicForm from "./DynamicForm.js";
import Tree from "./Tree.js";
import ProfileForm from "./formData.js";
import MyNavbar from "./Navbar.js";
import Navbar from "./Navbar.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Login.js";
import Signup from "./Signup.js";
import Forgotpassword from "./Forgotpassword.js";
import OtpCheck from "./OtpCheck.js";
import NewPassword from "./NewPassword.js";
import { ToastContainer, toast } from 'react-toastify';

function App() {
  return (
    <BrowserRouter>
  <ToastContainer/>
      <Routes>
      <Route path="/" element={<Login />} />

        <Route path="/mypage" element={<>
          <Navbar />
        <ProfileForm />
        </>} />
        <Route path="profile/:id" element={<Tree />} />
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="forgotpassword" element={<Forgotpassword />} />
        <Route path="otpcheck" element={<OtpCheck />} />
        <Route path="newpassword" element={<NewPassword />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
