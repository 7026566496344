import { useState } from "react";
import './Navbar.css'
import { Outlet, Link } from "react-router-dom";

function Navbar(props) {
  const [mobileMenu, toggleMobileMenu] = useState(false);
  const data = {
    logo: "logo.png",
    name: "ReactJS Navbar"
  };

  return (
    <div className="nav-wrapper">
      <div className="grad-bar"></div>
      <nav className="navbar"
        style={{
          paddingRight: "30px",
          paddingLeft: "30px"
        }}
      >
        <img src={data.logo} alt={data.name} />
        <div
          
          id="mobile-menu"
          onClick={(e) =>
            mobileMenu ? toggleMobileMenu(false) : toggleMobileMenu(true)
          }
        >
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
        <ul>
          <li className="nav-item"
            style={{
              fontFamily: "Cairo",
            }}
          >
            <Link to="/login">تسجيل الخروج</Link>
          </li>

        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
